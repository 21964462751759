@import './../variables';

.wrapper {
  height: 100%;
  padding: 24px 50px;
  display: flex;
  background: white;
}

.content {
  width: calc(100% - 308px);
}

.sider_right {
  width: 308px;
  padding-left: 24px;
  border-left: 1px solid $border-color-base;
}

.form {
  margin-top: 24px;
  padding-right: 24px;
  display: flex;
  flex-wrap: wrap;
}

.group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 24px;
  margin-bottom: 24px;
  border-top: 1px dashed #d0d0d0;
  border-bottom: 1px dashed #d0d0d0;

  & ~ .group {
    padding-top: 0;
    border-top: 0;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99;
  width: calc(100% - 208px);
  margin: 0;
  padding: 15px 50px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  background: white;
  border-top: 1px solid $border-color-base;
}

.submit{
  margin-right: 24px;
}

.export{
  margin-top: 24px;
}

.modal {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
}

.item {
  margin-bottom: 0;
  margin-right: 24px;
}

.captcha {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.code {
  width: 120px;
  height: 40px;
  margin-bottom: 24px;
  background: #d0d0d0;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
