@import './../variables';

.layout {
  min-height: 100vh;
}

.slider {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  background: white;
}

.footer {
  color: $text-color-secondary;
  text-align: center;
}

.wrapper {
  min-height: 100vh;
  padding-left: 208px;
}

.logo {
  display: block;
  padding: 24px 24px 12px;
  font-size: 38px;
  color: white;

  &:hover {
    color: white;
  }
}

.info {
  display: inline-flex;
  align-items: center;
}

.title {
  font-size: 20px;
}

.user {
  color: $text-color;
  font-size: 14px;
}

.name {
  margin-left: 10px;
  cursor: pointer;
}

.logout {
  border: 0;
  color: $text-color-secondary;
}
