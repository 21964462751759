@import './../variables';

.grid {
  cursor: pointer;
  text-align: center;
}

.text {
  margin-bottom: 4px;
  color: $text-color-secondary;
  font-size: 14px;
  line-height: 22px;
}

.count {
  font-size: 24px;
  line-height: 32px;
}

.wrapper {
  padding: 24px;
  background: $light-white;
}

.search {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px;
  background: white;
}

.item_group {
  display: flex;
  flex-wrap: wrap;
}

.item {
  min-width: 250px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.btn_group {
  width: auto;
}

.table {
  margin-top: 24px;
  padding: 24px;
  background: white;
}

.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99;
  width: calc(100% - 208px);
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  background: white;
}
