@import '~antd/dist/antd.css';
@import './variables';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.home {
  min-width: 1400px;

  .ant-tabs-nav {
    width: 228px;
  
    .ant-tabs-tab-btn {
      transition: none;
    }
  
    .ant-tabs-tab-active {
      background: #e6f7ff;
  
      .ant-tabs-tab-btn {
        text-shadow: none;
      }
    }
  }
  
  .ant-tabs-tabpane {
    .pane-content {
      padding-top: 24px;

      img {
        max-width: 100%;
      }
    }
  }

  .ant-steps-dot {
    width: 800px;
    margin-top: 33px;

    .ant-steps-item-tail {
      margin-left: 100px;
    }

    .ant-steps-item-icon {
      margin-left: 98px;
    }

    .ant-steps-item-content {
      width: 200px;
      margin-top: 0;
    }

    .ant-steps-item-title {
      font-size: 16px;
    }
  }
}

.supplier_form {
  .ant-form-large .ant-form-item-label > label {
    min-height: 40px;
    height: auto;
  }

  .ant-form-item.required .ant-form-item-label > label::before {
    content: '*';
    color: red;
    margin-right: 5px;
  }

  .ant-input[disabled],
  .ant-radio-disabled + span,
  .ant-checkbox-disabled + span,
  .ant-picker-input > input[disabled],
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: $text-color;
  }
}
