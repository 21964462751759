.wrapper {
  height: 100%;
  padding: 24px;
}

.tab {
  height: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  background: white;
}

.pane {
  padding: 0 24px;
}

.buttons {
  text-align: right;
}