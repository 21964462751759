@import './../variables';

.layout {
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-image: url(../../images/loginBg.svg);
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer {
  color: $text-color-secondary;
  text-align: center;
}

.title {
  margin-bottom: 40px;
  text-align: center;
}

.logo {
  width: 80%;
}

.form {
  width: 368px;
  min-height: 200px;
}

.icon {
  color: $primary-color;
}

.info {
  padding: 24px 0;
}

.captcha {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.code {
  width: 120px;
  height: 40px;
  margin-bottom: 24px;
  background: #d0d0d0;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
