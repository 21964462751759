.item {
  width: 33%;
  min-width: 390px;
  max-width: 510px;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 20px;
}

.group {
  width: 100%;
  .item {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 0;
    .formListItem {
      width: 33%;
      min-width: 390px;
      max-width: 510px;
      padding-right: 20px;
      padding-left: 20px;
      margin-bottom: 20px;
    }
    :global {
      .ant-card {
        .ant-card-body {
          display: flex;
          flex-wrap: wrap;
          padding: 16px 0;
        }
      }
    }
  }
}

.hidden {
  display: none;
}

.visible {
  display: inline-block;
}

.attach {
  margin-top: 20px;
}

.tag {
  cursor: pointer;
  white-space: normal;
}
